<template>
<div v-if="isPortfolioReady">
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        <span>
                            Create Assignment
                        </span>

                        <small v-if="selectedCourse">
                            {{ selectedCourse.courseSectionTitle || selectedCourse.name }}
                        </small>
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-group">
                        <!-- <b-button
                            v-if="developerKey && clientId"
                            variant="primary"
                            class="m-2"
                            title=" Select an attachment from google docs"
                            @click.stop.prevent="driveIconClicked"
                        >
                            <i class="la la-star" />
                            Attatchment from Docs
                        </b-button> -->
                        <b-button
                            variant="primary"
                            class="m-2"
                            @click.stop.prevent="save"
                        >
                            <i class="la la-star" />
                            Create
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="kt-section mb-0">
                    <div class="kt-section__body">
                        <div
                            v-if="docsImport"
                            class="pb-2"
                        >
                            <h4>Document selected from google docs: {{ docsImport.name }}</h4>
                        </div>
                        <LocalCourseWork ref="localCourseWork" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import LocalCourseWork from '../components/LocalCourseWork.vue';
import courseMixins, { getCourses, CourseFilter } from '../store/mixins/courseMixins';
import { courseWorkMixins } from '../store/mixins/courseWorkMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import { getSessionUser } from '../store/mixins/userMixins';
import blankAssignment from '../store/state/assignment';
import * as network from '../network';

import Types from '../store/Types';

export default Vue.extend({
    name: 'LocalCourseAssignmentCreate',
    components: {
        LocalCourseWork,
    },
    mixins: [
        courseMixins,
        courseWorkMixins,
        teacherMixins,
        portfolioMixins,
    ],
    data() {
        return {
            key: 0,
            saving: false,
            pickerApiLoaded: false,
            oauthToken: null,
            developerKey: '',
            clientId: '',
            scope: 'https://www.googleapis.com/auth/drive.readonly',
            docsImport: null,
            client: null,
            accessToken: '',
        };
    },
    computed: {
        courses() {
            return this.$store.state.database.assignmentCourseSections;
        },
        dirty() {
            return this.$store.state.database.assignmentIsDirty;
        },
        isNew() {
            return true;
        },
        courseWork() {
            return this.$store.state.database.assignment;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        assignmentCourseSections() {
            return this.$store.state.database.assignmentCourseSections;
        },
        primaryTeacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        primaryCourse() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        teacherCourses() {
            if (!this.primaryTeacher) return [];
            const { database } = this.$store.state;
            const { schoolStaffId } = this.primaryTeacher;
            const session = getSessionUser(this.$store.state);
            const courses = getCourses(database, new CourseFilter({ schoolStaffId, session }));
            return courses;
        },
        selectedCourse() {
            const { teacherCourses } = this;
            const course = this.$_courseMixins_getCourseFromRoute();
            const { courseSectionId } = course;
            return teacherCourses.find((cw) => cw.courseSectionId == courseSectionId) || null;
        },
        gradeTemplate() {
            if (!this.selectedCourse) return null;
            return this.selectedCourse.gradeTemplate;
        },
    },
    mounted() {
        this.populate();
        // this.loadDriveSelector();
    },
    methods: {
        save() {
            const { localCourseWork } = this.$refs;
            const valid = localCourseWork.isValid();
            if (!valid) return;

            const courseWork = { ...this.courseWork };
            let { saving } = this;
            const { showError, $router } = this;
            const fullFormat = 'YYYY-MM-DD HH:mm:ss';
            const dateFormat = 'YYYY-MM-DD';
            const timeFormat = 'HH:mm:ss';

            // format dueDate with time
            if (courseWork.dueDate) {
                const dueDate = moment(`${courseWork.dueDate} ${courseWork.dueTime || '12:00:00 AM'}`, fullFormat).utc();
                courseWork.dueDate = dueDate.format(dateFormat);
                courseWork.dueTime = dueDate.format(timeFormat);
            }

            // format publishDate with time
            if (courseWork.publishDate) {
                const publishDate = moment(`${courseWork.publishDate} ${courseWork.publishTime || '12:00:00 AM'}`, fullFormat).utc();
                courseWork.publishDate = publishDate.format(dateFormat);
                courseWork.publishTime = publishDate.format(timeFormat);
            }

            if (saving) return;
            saving = true;

            const { $store, $route } = this;
            const { gradeTemplate } = this;

            // for each course in assigned courses, create a courseWork
            const courseWorks = this.assignmentCourseSections
                .filter((x) => x.assigned)
                .map((x) => {
                    const cw = { ...courseWork };
                    cw.courseSectionId = x.courseSectionId;
                    return cw;
                });

            const action = Types.actions.ADD_LOCAL_COURSE_WORK;
            $store.dispatch(action, {
                courseWorks,
                gradeTemplate,
                callback(err) {
                    saving = false;
                    if (err) return showError(err);
                    $router.push({
                        name: 'TeacherCourseAssignments',
                        params: $route.params,
                    });
                },
            });
        },
        populate() {
            const courseWork = { ...blankAssignment };
            this.$store.commit(Types.mutations.SET_COURSE_WORK, courseWork);

            const { teacherCourses, primaryCourse } = this;
            const assignmentCourseSections = teacherCourses
                .map((c) => {
                    const course = { ...c };
                    course.assigned = c.courseSectionId == primaryCourse.courseSectionId;
                    return course;
                })
                .sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));

            // push active course to top
            const index = assignmentCourseSections.findIndex((c) => c.extCourseSectionId == this.$route.params.extCourseSectionId);
            if (index > -1) assignmentCourseSections.unshift(assignmentCourseSections.splice(index, 1)[0]);

            this.$store.commit(Types.mutations.SET_ASSIGNMENT_COURSE_SECTIONS, { assignmentCourseSections });
        },
        loadDriveSelector() {
            const { showError } = this;
            network.session.getKeys({}, (err, data) => {
                if (err) return showError(err);
                this.clientId = data.id;
                this.developerKey = data.key;

                const gApi = document.createElement('script');
                gApi.setAttribute('type', 'text/javascript');
                gApi.setAttribute('src', 'https://apis.google.com/js/api.js');
                document.head.appendChild(gApi);

                const gDrive = document.createElement('script');
                gDrive.setAttribute('type', 'text/javascript');
                gDrive.setAttribute('src', 'http://accounts.google.com/gsi/client');
                document.head.appendChild(gDrive);
            });
        },
        async driveIconClicked() {
            const client = google.accounts.oauth2.initTokenClient({
                client_id: this.clientId,
                scope: this.scope,
                callback: (response) => {
                    console.log('response', response);

                    this.oauthToken = response.access_token;
                    gapi.load('picker', () => {
                        console.log('Picker Loaded');
                        this.pickerApiLoaded = true;
                        this.createPicker();
                    });
                },
            });
            client.requestAccessToken();
        },
        handleAuthResult(authResult) {
            console.log('Handle Auth result', authResult);
            if (authResult && !authResult.error) {
                this.oauthToken = authResult.access_token;
                this.createPicker();
            }
        },
        createPicker() {
            if (this.pickerApiLoaded && this.oauthToken) {
                const picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .addView(google.picker.ViewId.DOCS)
                    .setOAuthToken(this.oauthToken)
                    .setDeveloperKey(this.developerKey)
                    .setCallback(this.pickerCallback)
                    .build();
                picker.setVisible(true);
            }
        },
        async pickerCallback(data) {
            console.log('PickerCallback', data);
            if (data.action === google.picker.Action.PICKED) {
                // Array of Picked Files
                this.docsImport = data.docs[0];
            }
        },
    },
});

</script>

<style scoped>

ul {
    flex-wrap: nowrap;
    overflow-x: scroll;
}
li.nav-item {
    width: 160px;
}

li.nav-item a.nav-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

label.kt-checkbox span {
    margin-top: 16px;
    margin-left: 18px;
}
label.kt-checkbox div.kt-spinner {
    position: absolute;
    top: 26px;
    left: 20px;
}

.tab-container {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    padding: 20px;
}

</style>
