var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isPortfolioReady
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _c("div", { staticClass: "kt-portlet" }, [
              _c("div", { staticClass: "kt-portlet__head" }, [
                _c("div", { staticClass: "kt-portlet__head-label" }, [
                  _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _c("span", [_vm._v(" Create Assignment ")]),
                    _vm.selectedCourse
                      ? _c("small", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedCourse.courseSectionTitle ||
                                  _vm.selectedCourse.name
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                  _c(
                    "div",
                    { staticClass: "kt-portlet__head-group" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "m-2",
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.save.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-star" }),
                          _vm._v(" Create "),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c("div", { staticClass: "kt-section mb-0" }, [
                  _c(
                    "div",
                    { staticClass: "kt-section__body" },
                    [
                      _vm.docsImport
                        ? _c("div", { staticClass: "pb-2" }, [
                            _c("h4", [
                              _vm._v(
                                "Document selected from google docs: " +
                                  _vm._s(_vm.docsImport.name)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("LocalCourseWork", { ref: "localCourseWork" }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }